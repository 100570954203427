<template>
    <button :class="classList"
            :disabled="disabled"
            :style="styleComponent"
            class="button">
        <slot/>
    </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    full: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    styleComponent: {},
  },
  computed: {
    classList() {
      let array = [];
      if (this.color) array = [...array, this.disabled ? 'button__disabled' : `button__${this.color}`];
      if (this.full) array = [...array, 'button__full'];
      return array;
    },
  },
};
</script>
